@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 200;
  src: local('InterThin'), local('Inter-Thin'), url('./static/font/Inter-3.19/Inter-Thin.woff2') format('woff2');
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: local('InterLight'), local('Inter-Light'), url('./static/font/Inter-3.19/Inter-Light.woff2') format('woff2');
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: local('Inter'), local('Inter-Regular'), url('./static/font/Inter-3.19/Inter-Regular.woff2') format('woff2');
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: local('InterMedium'), local('Inter-Medium'), url('./static/font/Inter-3.19/Inter-Medium.woff2') format('woff2');
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: local('InterBold'), local('Inter-Bold'), url('./static/font/Inter-3.19/Inter-Bold.woff2') format('woff2');
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: local('InterExtraBold'), local('Inter-ExtraBold'), url('./static/font/Inter-3.19/Inter-ExtraBold.woff2') format('woff2');
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src: local('InterBlack'), local('Inter-Black'), url('./static/font/Inter-3.19/Inter-Black.woff2') format('woff2');
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

b {
  font-weight: 500;
}

/**
 * Override global Material UI styles
 */
.MuiListItem-divider:last-child {
  border-bottom: none;
}
p.MuiTypography-root {
  margin-bottom: 12px;
}

a {
  color: #0b69ff;
  text-decoration: none;
}

/**
 *
 */
fieldset {
  border: 1px solid #ccc;
  margin: 0;
  padding: 2px 12px;
  border-radius: 5px;
}
fieldset:hover,
fieldset:focus,
fieldset:active {
  border-color: #000;
}
fieldset legend {
  padding: 0 6px;
  color: #555;
}

/**
 * Remove cross icon on search input
 */
 input[type="search"]::-webkit-search-decoration,
 input[type="search"]::-webkit-search-cancel-button,
 input[type="search"]::-webkit-search-results-button,
 input[type="search"]::-webkit-search-results-decoration {
   display: none;
 }

/**
 * Disable iOS zoom when input field is focused
 */
 input[type="color"],
 input[type="date"],
 input[type="datetime"],
 input[type="datetime-local"],
 input[type="email"],
 input[type="month"],
 input[type="number"],
 input[type="password"],
 input[type="search"],
 input[type="tel"],
 input[type="text"],
 input[type="time"],
 input[type="url"],
 input[type="week"],
 textarea,
 select:focus,
 textarea {
   font-size: 16px;
 }

 /**
  * Stripe
  */
.StripeElement {
  height: 40px;
  padding: 10px 12px;
  width: 100%;
  color: #32325d;
  background-color: white;
  border: 1px solid transparent;
  border-radius: 4px;

  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
