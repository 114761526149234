@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(./static/font/fontello/bridger-font.eot); /* For IE6-8 */
  src: local('Material Icons'),
    local('MaterialIcons-Regular'),
    url(./static/font/fontello/bridger-font.woff2) format('woff2'),
    url(./static/font/fontello/bridger-font.woff) format('woff'),
    url(./static/font/fontello/bridger-font.ttf) format('truetype');
}
.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
  text-transform: none;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1em;
  display: inline-block;
  text-decoration: inherit;
  text-align: center;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  speak: never;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}
.material-icons.icon-large {
  width: auto;
}
/* Rules for sizing the icon. */
.material-icons.md-18, .material-icons.MuiIcon-fontSizeXsmall { font-size: 12px; }
.material-icons.md-18, .material-icons.MuiIcon-fontSizeSmall { font-size: 18px; }
.material-icons.md-24, .material-icons.MuiIcon-fontSizeMedium { font-size: 24px; }
.material-icons.md-24, .material-icons.MuiIcon-fontSizeXmedium { font-size: 30px; }
.material-icons.md-36, .material-icons.MuiIcon-fontSizeLarge { font-size: 36px; }
.material-icons.md-48, .material-icons.MuiIcon-fontSizeXlarge { font-size: 48px; }

/* Rules for using icons as black on a light background. */
.material-icons.md-dark { color: rgba(0, 0, 0, 0.54); }
.material-icons.md-dark.md-inactive { color: rgba(0, 0, 0, 0.26); }

/* Rules for using icons as white on a dark background. */
.material-icons.md-light { color: rgba(255, 255, 255, 1); }
.material-icons.md-light.md-inactive { color: rgba(255, 255, 255, 0.3); }

/* List à récupérer depuis l'export de Fontello */

.material-icons.icon-alert-plain:before { content: '\e800'; }
.material-icons.icon-alert:before { content: '\e801'; }
.material-icons.icon-archive:before { content: '\e802'; }
.material-icons.icon-arrow-bottom:before { content: '\e803'; }
.material-icons.icon-arrow-left:before { content: '\e804'; }
.material-icons.icon-arrow-right:before { content: '\e805'; }
.material-icons.icon-arrow-top:before { content: '\e806'; }
.material-icons.icon-attach:before { content: '\e807'; }
.material-icons.icon-bag:before { content: '\e808'; }
.material-icons.icon-bell:before { content: '\e809'; }
.material-icons.icon-bill:before { content: '\e80a'; }
.material-icons.icon-box:before { content: '\e80b'; }
.material-icons.icon-bridger:before { content: '\e80c'; }
.material-icons.icon-category:before { content: '\e80d'; }
.material-icons.icon-chart:before { content: '\e80e'; }
.material-icons.icon-check-2:before { content: '\e80f'; }
.material-icons.icon-check-1:before { content: '\e810'; }
.material-icons.icon-check-3:before { content: '\e811'; }
.material-icons.icon-check-plain:before { content: '\e812'; }
.material-icons.icon-chevron-bottom:before { content: '\e813'; }
.material-icons.icon-chevron-left:before { content: '\e814'; }
.material-icons.icon-chevron-right:before { content: '\e815'; }
.material-icons.icon-chevron-top:before { content: '\e816'; }
.material-icons.icon-close:before { content: '\e817'; }
.material-icons.icon-coffee:before { content: '\e818'; }
.material-icons.icon-collect-bag:before { content: '\e819'; }
.material-icons.icon-collect-box:before { content: '\e81a'; }
.material-icons.icon-content-copy:before { content: '\e81b'; }
.material-icons.icon-credit-card:before { content: '\e81c'; }
.material-icons.icon-customer:before { content: '\e81d'; }
.material-icons.icon-delete-plain:before { content: '\e81e'; }
.material-icons.icon-delete:before { content: '\e81f'; }
.material-icons.icon-download:before { content: '\e820'; }
.material-icons.icon-edit:before { content: '\e821'; }
.material-icons.icon-euro:before { content: '\e822'; }
.material-icons.icon-eye-hide:before { content: '\e823'; }
.material-icons.icon-eye:before { content: '\e824'; }
.material-icons.icon-filters-x2:before { content: '\e825'; }
.material-icons.icon-filters-x3:before { content: '\e826'; }
.material-icons.icon-folder-delete:before { content: '\e827'; }
.material-icons.icon-folder:before { content: '\e828'; }
.material-icons.icon-help:before { content: '\e829'; }
.material-icons.icon-home-gears:before { content: '\e82a'; }
.material-icons.icon-home-orders:before { content: '\e82b'; }
.material-icons.icon-home-shop:before { content: '\e82c'; }
.material-icons.icon-home:before { content: '\e82d'; }
.material-icons.icon-inbox:before { content: '\e82e'; }
.material-icons.icon-info-plain:before { content: '\e82f'; }
.material-icons.icon-info:before { content: '\e830'; }
.material-icons.icon-library:before { content: '\e831'; }
.material-icons.icon-location:before { content: '\e832'; }
.material-icons.icon-lock:before { content: '\e833'; }
.material-icons.icon-login:before { content: '\e834'; }
.material-icons.icon-mail:before { content: '\e835'; }
.material-icons.icon-minus:before { content: '\e836'; }
.material-icons.icon-logout:before { content: '\e837'; }
.material-icons.icon-more-horizontal:before { content: '\e838'; }
.material-icons.icon-more-vertical:before { content: '\e839'; }
.material-icons.icon-order-in:before { content: '\e83a'; }
.material-icons.icon-order-out:before { content: '\e83b'; }
.material-icons.icon-order-progress:before { content: '\e83c'; }
.material-icons.icon-order-recurring:before { content: '\e83d'; }
.material-icons.icon-paper-plane:before { content: '\e83e'; }
.material-icons.icon-order:before { content: '\e83f'; }
.material-icons.icon-pdf:before { content: '\e840'; }
.material-icons.icon-pencil:before { content: '\e841'; }
.material-icons.icon-phone:before { content: '\e842'; }
.material-icons.icon-picture:before { content: '\e843'; }
.material-icons.icon-plus:before { content: '\e844'; }
.material-icons.icon-printer:before { content: '\e845'; }
.material-icons.icon-refresh-1:before { content: '\e846'; }
.material-icons.icon-refresh-2:before { content: '\e847'; }
.material-icons.icon-save:before { content: '\e848'; }
.material-icons.icon-search:before { content: '\e849'; }
.material-icons.icon-settings:before { content: '\e84a'; }
.material-icons.icon-share:before { content: '\e84b'; }
.material-icons.icon-ship:before { content: '\e84c'; }
.material-icons.icon-shipping:before { content: '\e84d'; }
.material-icons.icon-star:before { content: '\e84e'; }
.material-icons.icon-star-plain:before { content: '\e84f'; }
.material-icons.icon-store:before { content: '\e850'; }
.material-icons.icon-tag:before { content: '\e851'; }
.material-icons.icon-traffic-cone:before { content: '\e852'; }
.material-icons.icon-swap:before { content: '\e853'; }
.material-icons.icon-trash:before { content: '\e854'; }
.material-icons.icon-user-add:before { content: '\e855'; }
.material-icons.icon-unavailable:before { content: '\e856'; }
.material-icons.icon-user-delete:before { content: '\e857'; }
.material-icons.icon-user:before { content: '\e858'; }
.material-icons.icon-users:before { content: '\e859'; }
.material-icons.icon-woodkit:before { content: '\e85a'; }
.material-icons.icon-world:before { content: '\e85b'; }